/*=========================================================================================
    File Name: app-todo.js
    Description: app-todo
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

$(function() {
  "use strict";

  if (!$.app.menu.is_touch_device()) {
    // --------------------------------------------
    // Sidebar menu scrollbar
    // --------------------------------------------
    if ($('.todo-application .sidebar-menu-list').length > 0) {
      var content = new PerfectScrollbar('.sidebar-menu-list', {
        theme: "dark"
      });
    }

    // --------------------------------------------
    // Todo feature list scrollbar
    // --------------------------------------------
    if ($('.todo-application .todo-feature-list-right').length > 0) {
      var sidebar_todo = new PerfectScrollbar('.todo-feature-list-right', {
        theme: "dark",
        suppressScrollX: true
      });
    }
    // --------------------------------------------
    // Todo feature list scrollbar
    // --------------------------------------------
    if ($('.todo-application .todo-feature-list').length > 0) {
      var sidebar_todo = new PerfectScrollbar('.todo-feature-list', {
        theme: "dark",
        suppressScrollX: true
      });
    }
    if ($('.todo-application .participant-wrapper').length > 0) {
      var sidebar_todo = new PerfectScrollbar('.participant-wrapper', {
        theme: "dark",
        suppressScrollX: true
      });
    }
  }

});
